@import '../../styles/base/layout';

.top_grid_parent {

    .grid {

        display: grid;
        grid-template-columns: 33.33% 33.33% 33.33%;

        @media (min-width: map-get($grid-breakpoints,md)) {

            grid-template-columns: 20% 20% 20% 20% 20%;
        }

        .card_parent {

            margin-bottom: 24px;

            &:nth-last-child(-n+3){

                margin-bottom: 0px;

            }

            @media (min-width: map-get($grid-breakpoints,md)) {

                margin-bottom: 58px;

                &:nth-last-child(-n+5){

                    margin-bottom: 0px;
    
                }
            }

            .card {

                display: flex;
                flex-direction: column;
                align-items: center;
                text-decoration: none;
                color: black;
                margin-left: 4px;
                margin-right: 4px;
                text-align: center;

                @media (min-width: map-get($grid-breakpoints,md)) {
                    margin-left: 23px;
                    margin-right: 23px;
                }

                .image {
                    margin-bottom: 7px;
                    display: inline-grid;
                    position: relative;
                    &.is_new{  
                        &::before{
                                content: "New";
                                z-index: -1;
                                border-radius: 0px 4px;
                                background: var(--elan-semantic-red-1, #F24242);
                                box-shadow: 0px 4px 4px 0px rgba(100, 100, 100, 0.10);
                                display: flex;
                                width: 44px;
                                height: 22px;
                                padding: 1px 2px;
                                justify-content: center;
                                align-items: center;
                                flex-shrink: 0;
                                position: absolute;
                                left:-35px;
                                color: var(--osg-background-3, #FAFAFA);
                                text-align: center;
                                font-variant-numeric: lining-nums proportional-nums;
                                font-family: var(--font-bold);
                                font-size: 14px;
                                font-style: normal;
                                font-weight: 400;
                                line-height: normal;      
                                @media (max-width: map-get($grid-breakpoints,lg)) {
                                        left:-30px;
                                }   
                                @media (max-width: map-get($grid-breakpoints,md)) {
                                        left:-30px;
                                        font-size: 12px;
                                        width:32px;
                                        height: 16px;
                                }        
                        }
                     }
                }

                .bg_circle {
                    border-radius: 50%;
                    background-color: #E9F6FF;
                    position: absolute;
                    z-index: -1;
                }
                .sprite {
                    background: url(../../public/static/images/home/revamp/top-grid/sprite_devices.webp) no-repeat;
                    border-radius: 50%;
                    height: 80px;
                    width: 6240px;
                    background-size: cover;
                }
                
                .mobile_sprite {
                    background: url(../../public/static/images/home/revamp/top-grid/sprite_devices_mobile.webp) no-repeat;
                    border-radius: 50%;
                    height: 54px;
                    width: 2106px;
                    background-size: cover;
                }

                .mobile_sprite.iprotect_gold{
                    background-position: 0px 0;
                }
                .mobile_sprite.icon_mobile_screen_damage {
                    background-position: -54px 0;
                }
                .mobile_sprite.icon_mobile_total_care{
                    background-position: -108px 0; 
                }
                .mobile_sprite.icon_mobile {
                    background-position: -162px 0;
                }
                .mobile_sprite.icon_laptop {
                    background-position: -216px 0;
                }
                .mobile_sprite.icon_laptop_damage {
                    background-position: -270px 0;
                }
                .mobile_sprite.icon_laptop_total_care{
                    background-position: -324px 0;
                }
                .mobile_sprite.icon_tablet {
                    background-position: -378px 0;
                }
                .mobile_sprite.icon_groom_hair_care {
                    background-position: -432px 0;
                }
                .mobile_sprite.icon_digital_camera {
                    background-position: -486px 0;
                }
                .mobile_sprite.icon_television {
                    background-position: -540px 0;
                }
                .mobile_sprite.icon_mixer {
                    background-position: -594px 0;
                }
                .mobile_sprite.icon_microwave {
                    background-position: -648px 0;
                }
                .mobile_sprite.icon_smartwatch {
                    background-position: -702px 0;
                }
                .mobile_sprite.icon_refrigerator {
                    background-position: -756px 0;
                }
                .mobile_sprite.icon_chimney {
                    background-position: -810px 0;
                } 
                .mobile_sprite.icon_room_cooler {
                    background-position: -864px 0;
                }   
                .mobile_sprite.icon_grinder {
                    background-position: -918px 0;
                }
                .mobile_sprite.icon_iron {
                    background-position: -972px 0;
                }
                .mobile_sprite.icon_geyser {
                    background-position: -1026px 0;
                }
                .mobile_sprite.icon_wp_repair {
                    background-position: -1080px 0;
                }
                .mobile_sprite.icon_washing_machine {
                    background-position: -1134px 0;
                }
                .mobile_sprite.icon_ac_service_repair {
                    background-position: -1188px 0;
                }
                .mobile_sprite.icon_ac_extended_warranty {
                    background-position: -1188px 0;
                }
                .mobile_sprite.icon_30 {
                    background-position: -1242px 0;
                }
                .mobile_sprite.icon_stoove {
                    background-position: -1296px 0;
                }
                .mobile_sprite.icon_dishwasher {
                    background-position: -1350px 0;
                }
                .mobile_sprite.icon_27 {
                    background-position: -1404px 0;
                }
                .mobile_sprite.icon_fan {
                    background-position: -1458px 0;
                }
                .mobile_sprite.icon_smart_watch {
                    background-position: -1512px 0;
                }
                .mobile_sprite.icon_kettle {
                    background-position: -1566px 0;
                }
                .mobile_sprite.icon_cooker {
                    background-position: -1620px 0;
                }
                .mobile_sprite.icon_printer_scanner {
                    background-position: -1674px 0;
                }
                .mobile_sprite.icon_audio_system {
                    background-position: -1728px 0;
                }
                .mobile_sprite.icon_gaming_console {
                    background-position: -1782px 0;
                }
                .mobile_sprite.icon_headphone {
                    background-position: -1836px 0;
                }
                .mobile_sprite.icon_home_theatre {
                    background-position: -1890px 0;
                }
                .mobile_sprite.icon_room_cleaner {
                    background-position: -1944px 0;
                }
                .mobile_sprite.icon_ac_maintenance_plans {
                    background-position: -1998px 0;
                }

                .sprite.iprotect_gold{
                    background-position: 0px 0;
                }
                .sprite.icon_mobile_screen_damage {
                    background-position: -80px 0;
                }
                .sprite.icon_mobile_total_care{
                    background-position: -160px 0; 
                }
                .sprite.icon_mobile {
                    background-position: -240px 0;
                }
                .sprite.icon_laptop {
                    background-position: -320px 0;
                }
                .sprite.icon_laptop_damage {
                    background-position: -400px 0;
                }
                .sprite.icon_laptop_total_care{
                    background-position: -480px 0;
                }
                .sprite.icon_tablet {
                    background-position: -560px 0;
                }
                .sprite.icon_groom_hair_care {
                    background-position: -640px 0;
                }
                .sprite.icon_digital_camera {
                    background-position: -720px 0;
                }
                .sprite.icon_television {
                    background-position: -800px 0;
                }
                .sprite.icon_mixer {
                    background-position: -880px 0;
                }
                .sprite.icon_microwave {
                    background-position: -960px 0;
                }
                .sprite.icon_smartwatch {
                    background-position: -1040px 0;
                }
                .sprite.icon_refrigerator {
                    background-position: -1120px 0;
                }
                .sprite.icon_chimney {
                    background-position: -1200px 0;
                } 
                .sprite.icon_room_cooler {
                    background-position: -1280px 0;
                }   
                .sprite.icon_grinder {
                    background-position: -1360px 0;
                }
                .sprite.icon_iron {
                    background-position: -1440px 0;
                }
                .sprite.icon_geyser {
                    background-position: -1520px 0;
                }
                .sprite.icon_wp_repair {
                    background-position: -1600px 0;
                }
                .sprite.icon_washing_machine {
                    background-position: -1680px 0;
                }
                .sprite.icon_ac_service_repair {
                    background-position: -1760px 0;
                }
                .sprite.icon_ac_extended_warranty {
                    background-position: -1760px 0;
                }
                .sprite.icon_30 {
                    background-position: -1840px 0;
                }
                .sprite.icon_stoove {
                    background-position: -1920px 0;
                }
                .sprite.icon_dishwasher {
                    background-position: -2000px 0;
                }
                .sprite.icon_27 {
                    background-position: -2080px 0;
                }
                .sprite.icon_fan {
                    background-position: -2160px 0;
                }
                .sprite.icon_smart_watch {
                    background-position: -2240px 0;
                }
                .sprite.icon_kettle {
                    background-position: -2320px 0;
                }
                .sprite.icon_cooker {
                    background-position: -2400px 0;
                }
                .sprite.icon_printer_scanner {
                    background-position: -2480px 0;
                }
                .sprite.icon_audio_system {
                    background-position: -2560px 0;
                }
                .sprite.icon_gaming_console {
                    background-position: -2640px 0;
                }
                .sprite.icon_headphone {
                    background-position: -2720px 0;
                }
                .sprite.icon_home_theatre {
                    background-position: -2800px 0;
                }
                .sprite.icon_room_cleaner {
                    background-position: -2880px 0;
                }
                .sprite.icon_ac_maintenance_plans {
                    background-position: -2960px 0;
                }
                .dicount_container{

                    width: 74px;
                    background-color: #008CF0;
                    border: 1px solid #008CF0;
                    border-radius: 0px 4px;
                    padding-top: 1px;
                    padding-bottom: 1px;
                    margin-left: auto;
                    margin-right: auto;
                    margin-top: -12px;

                    @media (min-width: map-get($grid-breakpoints,md)) {
                        width: 88px;
                        margin-top: -1px;
                    }

                    .discount_text{

                        font-size: 10px;
                        font-family: var(--font-bold);
                        line-height: 12px;
                        text-align: center;
                        color: #ffffff;

                        @media (min-width: map-get($grid-breakpoints,md)) {
                            font-size: 12px;
                            line-height: 14px;
                        }

                    }
                }
                .lable {

                    font-family: var(--font-regular);
                    font-size: 12px;
                    line-height: 14px;
                    color: #000000;
                    margin-top: 7px;

                    display: -webkit-box;
                    -webkit-line-clamp: 2;
                    -webkit-box-orient: vertical;
                    overflow: hidden;
                    text-overflow: ellipsis;

                    @media (min-width: map-get($grid-breakpoints,md)) {

                        font-family: var(--font-semi-bold);
                        font-size: 16px;
                        line-height: 19px;
                        margin-top: 9px;
                    }
                }
            }
        }
    }
}